import Vue from 'vue';
import axios from 'axios';

class ApiService {
  constructor() {
    this.api = '/api';
  }

  static get Instance() {
    return this.instance || (this.instance = new this());
  }

  register(attrs) {
    return axios.post(`${this.api}/accounts`, attrs).then((result) => {
      return result.data;
    });
  }

  login(credentials) {
    return axios.post(`${this.api}/auth/login`, credentials).then((result) => {
      return result.data;
    });
  }

  heartbeat() {
    return axios.get(`${this.api}/auth/heartbeat`).then((result) => {
      return result.data;
    });
  }

  profile() {
    return axios.get(`${this.api}/accounts`).then((result) => {
      return result.data;
    });
  }

  claims() {
    return axios.get(`${this.api}/auth/claims`).then((result) => {
      return result.data;
    });
  }

  secondaryEmail(key) {
    return axios.get(`${this.api}/accounts/secondaryemail?userId=` + key).then((result) => {
      return result.data;
    });
  }

  confirmSecondaryEmail(key) {
    return axios.get(`${this.api}/accounts/confirmSecondaryEmail?q=` + key).then((result) => {
      return result.data;
    });
  }

  notify() {
    return axios.post(`${this.api}/accounts/notify`).then((result) => {
      return result.data;
    });
  }

  resendNotify(data) {
    return axios.post(`${this.api}/accounts/resendnotify`, data).then((result) => {
      return result.data;
    });
  }

  seed() {
    return axios.post(`${this.api}/accounts/seed`).then((result) => {
      return result.data;
    });
  }

  confirm(credentials) {
    return axios.post(`${this.api}/accounts/confirm`, credentials).then((result) => {
      return result.data;
    });
  }

  config(key) {
    return axios.get(`${this.api}/configuration?key=` + key).then((result) => {
      return result.data;
    });
  }

  forgotPassword(credentials) {
    return axios.post(`${this.api}/accounts/forgotpassword`, credentials).then((result) => {
      return result.data;
    });
  }

  resetPassword(credentials) {
    return axios.post(`${this.api}/accounts/resetpassword`, credentials).then((result) => {
      return result.data;
    });
  }

  form(params) {
    return axios.post(`${this.api}/form`, params).then((result) => {
      return result.data;
    });
  }

  formResponse(response) {
    return axios.post(`${this.api}/form/formresponse`, response).then((result) => {
      return result.data;
    });
  }

  fileUpload(file, fileSetId, entityTypeId, formElementId) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileSetId', fileSetId);
    formData.append('entityTypeId', entityTypeId);
    formData.append('formElementId', formElementId);
    return axios
      .post(`${this.api}/file/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((result) => {
        return result.data;
      });
  }

  fileDownload(fileKey) {
    return axios({
      url: `${this.api}/file/u/${fileKey}`,
      method: 'GET',
      responseType: 'blob'
    }).then((result) => {
      return result;
    });
  }

  staticFileDownload(fileCode) {
    return axios({
      url: `${this.api}/file/s/${fileCode}`,
      method: 'GET',
      responseType: 'blob'
    }).then((result) => {
      return result;
    });
  }

  reportDownload(fileName) {
    return axios({
      url: `${this.api}/file/r/${fileName}`,
      method: 'GET',
      responseType: 'blob'
    }).then((result) => {
      return result;
    });
  }

  staticFileUrl(fileCode) {
    return axios({
      url: `${this.api}/file/l/${fileCode}`,
      method: 'GET',
      responseType: 'string'
    }).then((result) => {
      return result.data;
    });
  }

  qrisCycle() {
    return axios({
      url: `${this.api}/reporting/qriscycle`,
      method: 'GET',
      responseType: 'string'
    }).then((result) => {
      return result.data;
    });
  }

  fileDelete(response) {
    return axios.delete(`${this.api}/file`, { data: response }).then((result) => {
      return result.data;
    });
  }

  gridData(response) {
    return axios.post(`${this.api}/grid`, response).then((result) => {
      return result.data;
    });
  }

  gridSummary(response) {
    return axios.post(`${this.api}/grid/summary`, response).then((result) => {
      return result.data;
    });
  }

  gridDisable(response) {
    return axios.post(`${this.api}/grid/disable`, response).then((result) => {
      return result.data;
    });
  }

  gridDelete(response) {
    return axios.post(`${this.api}/grid/delete`, response).then((result) => {
      return result.data;
    });
  }

  termsAccept(response) {
    return axios.post(`${this.api}/accounts/acceptterms?userId=${response.userId}`).then((result) => {
      return result.data;
    });
  }

  emailChange(response) {
    return axios.post(`${this.api}/accounts/emailchange`, response).then((result) => {
      return result.data;
    });
  }

  autoCompleteSearch(response) {
    return axios.post(`${this.api}/form/autocomplete`, response).then((result) => {
      return result.data;
    });
  }

  entityCrossReferenceSearch(response) {
    return axios.post(`${this.api}/form/entitycrossreference`, response).then((result) => {
      return result.data;
    });
  }

  entityCrossReferencePropertySearch(response) {
    return axios.post(`${this.api}/form/entitycrossreferenceproperty`, response).then((result) => {
      return result.data;
    });
  }

  sendMessage(response) {
    return axios.post(`${this.api}/message/send`, response).then((result) => {
      return result.data;
    });
  }

  getMessages(response) {
    return axios.post(`${this.api}/message`, response).then((result) => {
      return result.data;
    });
  }

  getMessageCount(response) {
    if (!response) response = { folder: 'Inbox', filter: 'Unread' };
    return axios.post(`${this.api}/message/count`, response).then((result) => {
      return result.data;
    });
  }

  messageAction(response) {
    return axios.post(`${this.api}/message/action`, response).then((result) => {
      return result.data;
    });
  }

  messageUserSearch(response) {
    return axios.post(`${this.api}/message/usersearch`, response).then((result) => {
      return result.data;
    });
  }

  sendConfEmailRequest(response) {
    return axios.post(`${this.api}/accounts/sendconfemail`, response).then((result) => {
      return result.data;
    });
  }

  formFlatFile(response) {
    return axios.post(`${this.api}/form/flatfile`, response).then((result) => {
      return result.data;
    });
  }

  userOrganizationRoles(response) {
    return axios.get(`${this.api}/userorganizationroles`, { params: response }).then((result) => {
      return result.data;
    });
  }

  userOrganizationRolesUpdate(response) {
    return axios.post(`${this.api}/userorganizationroles`, response).then((result) => {
      return result.data;
    });
  }

  addAccountRole(response) {
    return axios.post(`${this.api}/accountroles`, response).then((result) => {
      return result.data;
    });
  }

  removeAccountRole(response) {
    return axios.delete(`${this.api}/accountroles`, { data: response }).then((result) => {
      return result.data;
    });
  }

  rolePromotionRequest(response) {
    return axios.post(`${this.api}/userorganizationroles/request`, response).then((result) => {
      return result.data;
    });
  }

  organizationRequests(response) {
    return axios.get(`${this.api}/userorganizationroles/requests`, { params: response }).then((result) => {
      return result.data;
    });
  }

  requestAction(response) {
    return axios.post(`${this.api}/userorganizationroles/requestaction`, response).then((result) => {
      return result.data;
    });
  }

  verificationAction(response) {
    return axios.post(`${this.api}/verification`, response).then((result) => {
      return result.data;
    });
  }

  agencySearch(response) {
    return axios
      .get(`${this.api}/userorganizationroles/agencysearch`, {
        params: response
      })
      .then((result) => {
        return result.data;
      });
  }

  countySearch(response) {
    return axios
      .get(`${this.api}/userorganizationroles/countysearch`, {
        params: response
      })
      .then((result) => {
        return result.data;
      });
  }

  courseSearch(response) {
    return axios
      .get(`${this.api}/course/trainingsearch`, {
        params: response
      })
      .then((result) => {
        return result.data;
      });
  }

  courseSectionSearch(response) {
    return axios
      .get(`${this.api}/coursesection/trainingsearch`, {
        params: response
      })
      .then((result) => {
        return result.data;
      });
  }

  deleteCourse(response) {
    return axios
      .post(`${this.api}/course/deletecourse`, response)
      .then((result) => {
        return result.data;
      });
  }

  deleteSection(response) {
    return axios
      .post(`${this.api}/coursesection/deletesection`, response)
      .then((result) => {
        return result.data;
      });
  }

  courseProps(response) {
    return axios
      .get(`${this.api}/course/trainingprops`, {
        params: response
      })
      .then((result) => {
        return result.data;
      });
  }

  courseRegistration(response) {
    return axios
      .post(`${this.api}/enrollment/register`,response)
      .then((result) => {
        return result.data;
      });
  }

  courseWaitlist(response) {
    return axios
      .post(`${this.api}/enrollment/waitlist`,response)
      .then((result) => {
        return result.data;
      });
  }

  requestRegistration(response) {
    return axios
      .post(`${this.api}/enrollment/requestregistration`,response)
      .then((result) => {
        return result.data;
      });
  }

  cancelRegistration(response) {
    return axios
      .post(`${this.api}/enrollment/cancelregistration`,response)
      .then((result) => {
        return result.data;
      });
  }

  acceptRejectOverrideRequest(response) {
    return axios
      .post(`${this.api}/enrollment/regionoverride`, response)
      .then((result) => {
        return result.data;
      });
  }

  setAttendance(response) {
    return axios
    .post(`${this.api}/enrollment/setattendance`,response)
    .then((result) => {
      return result.data;
    });   
  }

  courseSectionProps(response) {
    return axios
      .get(`${this.api}/coursesection/sectionprops`, {
        params: response
      })
      .then((result) => {
        return result.data;
      });
  }

  courseSectionDetail(response) {
    return axios
      .get(`${this.api}/coursesection/getsectionbyid`, {
        params: response
      })
      .then((result) => {
        return result.data;
      });
  }

  enrollmentProps(response) {
    return axios
      .get(`${this.api}/enrollment/enrollmentprops`, {
        params: response
      })
      .then((result) => {
        return result.data;
      });
  }

  regionCheck(response) {
    return axios
      .get(`${this.api}/coursesection/regioncheck`, {
        params: response
      })
      .then((result) => {
        return result.data;
      });
  }

  getOwnOrgs() {
    return axios
      .get(`${this.api}/enrollment/getownorgs`)
      .then((result) => {
        return result.data;
      });
  }

  getUserOrgs(response) {
    return axios
      .get(`${this.api}/enrollment/getuserorgs`, {
        params: response
      })
      .then((result) => {
        return result.data;
      });
  }

  pilotCheck() {
    return axios
      .get(`${this.api}/accounts/pilotcheck`)
      .then((result) => {
        return result.data;
      });
  }

  emailConfirm(response) {
    return axios.post(`${this.api}/accounts/emailconfirm`, response ).then((result) => {
      return result.data;
    });
  }

  enrollmentTrainingSearch(response) {
    return axios
      .get(`${this.api}/enrollment/trainingsearch`, {
        params: response
      })
      .then((result) => {
        return result.data;
      });
  }

  calendarEvents() {
    return axios
      .get(`${this.api}/enrollment/calendarevents`)
    .then((result) => {
      return result.data;
    });
  }

  trainerCalendarEvents() {
    return axios
      .get(`${this.api}/enrollment/trainercalendarevents`)
    .then((result) => {
      return result.data;
    });
  }

  enrollmentUserSearch(response) {
    return axios
      .get(`${this.api}/enrollment/usersearch`, {
        params: response
      })
      .then((result) => {
        return result.data;
      });
  }

  agencySearchByCounty(response) {
    return axios
      .get(`${this.api}/userorganizationroles/searchagencybycounty`, {
        params: response
      })
      .then((result) => {
        return result.data;
      });
  }

  agencyReport(response) {
    return axios.post(`${this.api}/reporting/agencyreport`, response).then((result) => {
      return result.data;
    });
  }

  organizationEmployeesReport(response) {
    return axios.post(`${this.api}/reporting/organizationemployees`, { response }).then((result) => {
      return result.data;
    });
  }

  complianceReport(response) {
    return axios.post(`${this.api}/reporting/compliancereport`, response).then((result) => {
      return result.data;
    });
  }

  cachedComplianceReport(response) {
    return axios.get(`${this.api}/reporting/compliancereport`, { params: response }).then((result) => {
      return result.data;
    });  
  }

  verificationHistory(response) {
    return axios.get(`${this.api}/verification`, { params: response }).then((result) => {
      return result.data;
    });
  }

  verificationRequest(response) {
    return axios.post(`${this.api}/verification/request`, response)
    .then((result) => {
      return result.data;
    });
  }

  roleSwitch(response) {
    return axios.post(`${this.api}/userorganizationroles/roleswitch`, response).then((result) => {
      return result.data;
    });
  }

  directorReport(response) {
    return axios.post(`${this.api}/reporting/directorreport`, response).then((result) => {
      return result.data;
    });
  }

  adminReport(response) {
    return axios.post(`${this.api}/reporting/adminreport`, response).then((result) => {
      return result.data;
    });
  }

  supervisorReport(response) {
    return axios.post(`${this.api}/reporting/supervisorreport`, response).then((result) => {
      return result.data;
    });
  }

  coachReport(response) {
    return axios.post(`${this.api}/reporting/coachreport`, response).then((result) => {
      return result.data;
    });
  }

  qeaReport(response) {
    return axios.post(`${this.api}/reporting/qeareport`, response).then((result) => {
      return result.data;
    });
  }

  preSubmissionValidation(response) {
    return axios.post(`${this.api}/form/presubmissionvalidation`, response).then((result) => {
      return result.data;
    });
  }

  actionHistory(response) {
    return axios.get(`${this.api}/userorganizationroles/actionhistory`, { params: response }).then((result) => {
      return result.data;
    });
  }

  getNotifications(response) {
    return axios.post(`${this.api}/notification`, response).then((result) => {
      return result.data;
    });
  }

  getNotificationCount(response) {
    if (!response) response = { folder: 'Inbox', filter: 'Unread' };
    return axios.post(`${this.api}/notification/count`, response).then((result) => {
      return result.data;
    });
  }

  notificationAction(response) {
    return axios.post(`${this.api}/notification/action`, response).then((result) => {
      return result.data;
    });
  }

  getAlerts(response) {
    return axios.get(`${this.api}/alert`, { params: response }).then((result) => {
      return result.data;
    });
  }

  dismissAlert(response) {
    return axios.post(`${this.api}/alert/dismiss`, response).then((result) => {
      return result.data;
    });
  }
  getQuickSightURL(response) {
    return axios.get(`${this.api}/getdashboardurl`, response).then((result) => {
      return result.data;
    });
  }
  coachRelationshipAction(response) {
    return axios.post(`${this.api}/coach/coachrelationshipaction`, response).then((result) => {
      return result.data;
    });
  }
  requestedCoaches(response) {
    return axios.get(`${this.api}/coach/requests`, { params: response }).then((result) => {
      return result.data;
    });
  }
  unlockUser(response) {
    return axios.post(`${this.api}/accounts/unlock?userId=` + response.userId).then((result) => {
      return result.data;
    });
  }

  createResume() {
    return axios.post(`${this.api}/generatedResume/create`).then((result) => {
      return result.data;
    });
  }

  viewResumeFields(response) {
    return axios.get(`${this.api}/generatedResume/viewFields?resumeId=` + response.resumeId).then((result) => {
      return result.data;
    });
  }

  editResume(response) {
    return axios.post(`${this.api}/generatedResume/edit`, response).then((result) => {
      return result.data;
    });
  }

  publishResume(response) {
    return axios.post(`${this.api}/generatedResume/publish?resumeId=` + response.resumeId).then((result) => {
      return result.data;
    });
  }

  downloadResume(response) {
    return axios({
      url: `${this.api}/generatedResume/download/${response.fileKey}`,
      method: 'GET',
      responseType: 'blob'
    }).then((result) => {
      return result;
    });
  }

  submitReport(response) {
    return axios.post(`${this.api}/reporting/submit`, response).then((result) => {
      return result.data;
    });
  }

  reportResponse(response) {
    return axios.post(`${this.api}/reporting/respond`, response).then((result) => {
      return result.data;
    });
  }

  trainTNAccess(response) {
    return axios.post(`${this.api}/accounts/trainTNAccess`, response).then((result) => {
      return result.data;
    });
  }

  orgInfoById(response) {
    return axios.get(`${this.api}/accounts/organization?orgId=${response.orgId}`, response).then((result) => {
      return result.data;
    });
  }

  attestationList(response) {
    return axios.get(`${this.api}/attestation?userId=${response.userId}`, response).then((result) => {
      return result.data;
    });
  }

  setAttestation(response) {
    return axios.post(`${this.api}/attestation`, response).then((result) => {
      return result.data;
    });
  }

  endorsementDetail(response) {
    return axios.get(`${this.api}/endorsement/detail/${response.id}`, response).then((result) => {
      return result.data;
    });
  }

}

// export a singleton instance in the global namespace
export const apiService = ApiService.Instance;
