<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">Search Users</h1>
          <p>Use search feature to find an EC professional and view his/her records.</p>
        </div>
        <div>
          <toggleSearches @agencyInput="agencySelected"
                          @nameInput="nameSearched"
                          @countyInput="countySelected"
                          :agencySearchHelpText="agencySearchHelpText"
                          :userSearchHelpText="userSearchHelpText"
                          :countySearchHelpText="countySearchHelpText">
          </toggleSearches>
        </div>
        <div v-if="showAgencyReportButton" class="float-left mb-3">
            <button class="btn btn-wfr mr-2" @click="showSummary">Agency Summary</button>
        </div>
        <div v-if="gridKey.startsWith('open')">
          <grid :entityTypeId="userEntityType"
                :formId="userFormId"
                :entityTypeName="entityTypeName"
                :additionalActions="additionalActions"
                :checkForAdditionalActions="shouldShowAdditionalActions"
                :showFormOnEmpty="false"
                :showAddButton="false"
                :key="gridKey"
                :primaryEntity="gridPrimaryEntity"
                :showDisable="false"
                :showEdit="false"
                :showView="false"
                ref="userGrid"
                :additionalShowColumns="additionalColumns"
                :additionalActionsOnColumn="true"
                :allowDownload="false"
                :defaultFilters="gridDefaultFilters"
                :basePageSize="gridPageSize"
                :baseCurrentPage="gridCurrentPage"
                @setPageSize="setPageSize"
                @setCurrentPage="setCurrentPage"              
              >
          </grid>
        </div>
        <div v-if="gridKey == 'closed'" style="min-height: 200px"></div>
      </div>
    </div>

    <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="isModalVisible">
      <h2 slot="header">User Records - {{ activeUser.fullName }}</h2>
      <div class="text-left row" slot="body">
        <div v-if="activeUser.id" class="w-100 m-3">
          <userRecordsView :key="activeId"
                           :user="activeUser"
                           @dirty="formDirty($event)"
                           :organizationId="filterOrganizationId">
          </userRecordsView>
        </div>
      </div>
    </modal>
    <modal v-show="isSummaryModalVisible"
            @close="closeSummary"
            :isModalVisible="isSummaryModalVisible"
            :mustViewAll="false"
            :modalBusy="isBusy">
        <h2 slot="header"  class="text-center" style="width: 100%" v-if="isSummaryModalVisible">QRIS REPORT PERIOD<br/>
          {{ getQrisStartDate() }} 
          - 
          {{ getQrisEndDate() }}</h2>
        <div slot="body" v-if="isSummaryModalVisible">
          <grid :entityTypeId="summaryEntityType"
                :formId="8"
                :entityTypeName="summaryEntityTypeName"
                :showFormOnEmpty="false"
                :showAddButton="false"
                :allowDownload="false"
                :key="filterOrganizationId + '0' + summaryKeyAdd"
                :primaryEntity="gridPrimaryEntity"
                :showDisable="false"
                :showEdit="false"
                :showView="false"
                ref="summaryGrid"
                :additionalActions="summaryAdditionalActions"
                :additionalActionsOnColumn="true"
                :basePageSize="summaryGridPageSize"
                :baseCurrentPage="summaryGridCurrentPage"
                @setPageSize="setSummaryPageSize"
                @setCurrentPage="setSummaryCurrentPage"></grid>
          <b-button variant="wfr"
                    @click="getAgencyReport"
                    class="btn btn-wfr mr-4 float-right"
                    title="Download Agency Summary PDF"
                    id="pdf"
                    style="position:relative;bottom: 45px;">
            <icon icon="file-pdf" size="lg"></icon>
          </b-button>
        </div>
    </modal>
    <modal v-show="isTrainingReportModalVisible"
           @close="closeTrainingReportModal"
           :isModalVisible="isTrainingReportModalVisible"
           :modalBusy="!fileUrl"
           :skinName="'doc-viewer'">
      <h2 slot="header">Training Report</h2>
      <div class="text-left row" slot="body">
        <div class="col">
          <docViewer v-if="fileUrl" :downloadButtonText="'Download Training Report'" :fileUrl="fileUrl" :isStatic="false"></docViewer>
        </div>
      </div>
    </modal>
    <modal v-show="isArchivedReportsModalVisible" 
      @close="closeArchivedReportsModal" 
      :isModalVisible="isArchivedReportsModalVisible">
      <h2 slot="header">Archived Training Reports</h2>
      <archivedTrainingReports 
        v-if="activeUser.employmentRecordId"
        :employmentRecordId="activeUser.employmentRecordId"
        slot="body"></archivedTrainingReports>
    </modal>
  </section>
</template>

<script>
import toggleSearches from './toggle-searches.vue';
import userRecordsView from './user-records-view.vue';
import { getText, locale } from '@scripts/localized';
import { mapGetters } from 'vuex';
import docViewer from '@components/utility/doc-viewer';
import archivedTrainingReports from '@components/dashboard-items/management/archived-training-reports';

export default {
  name: 'SearchUsers',
  components: {
    toggleSearches,
    userRecordsView,
    docViewer,
    archivedTrainingReports
  },
  data() {
    return {
      locale: locale,
      filterOrganizationId: '',
      filterCountyId: '',
      isBusy: false,
      isTrainingReportModalVisible: false,
      fileUrl: '',
      userEntityType: 1,
      userFormId: 1,
      entityTypeName: 'User',
      summaryEntityType: 33,
      summaryEntityTypeName: 'Compliance Report',
      organizationEntity: {},
      countyEntity: {},
      gridPrimaryEntity: {},
      additionalActions: [
        {
          title: 'Verification Actions',
          icon: 'user-shield',
          click: this.rowAction,
          check: this.shouldShowAction
        },
        {
          title: 'Training Report',
          icon: 'book',
          click: this.getReport,
          check: this.shouldShowReportLink
        },
        {
          title: 'Archived Training Reports',
          icon: 'archive',
          click: this.getArchivedReports,
          check: this.shouldShowArchivedReportsLink
        }
      ],
      summaryAdditionalActions: [
                  {
          title: 'Training Report',
          icon: 'book',
          click: this.getReport,
          check: this.shouldShowReportLink
        }
      ],
      additionalColumns: [],
      agencyAdditionalColumns: ['titlePosition'],
      isModalVisible: false,
      activeId: '',
      activeUser: {},
      isDirty: false,
      searchedName: '',
      gridKey: 'closed',
      searchDefaultFilters: [
        {
          operation: 'Contains',
          filterValue: '',
          filterColumn: { propertyName: 'SearchableName' }
        },
        {
          operation: 'Equals',
          filterValue: 'true',
          filterColumn: { propertyName: 'HasECProfessionalRoleOnly' }
        }
      ],
      gridDefaultFilters: [],
      agencySearchHelpText: `Search by child care <u>${getText('agency').toLowerCase()}
        name</u> to see staff associated with that specific ${getText('program')}.
        Click on the ${getText('program')} name. Once displayed, click on action icon to view staff records.`,
      userSearchHelpText:
        `Search by <u>EC professional's name.</u> Once displayed, click on action icon to view staff records.`,
      countySearchHelpText:
        `Search by <u>${getText('agency').toLowerCase()} county</u>. Once displayed, click on action icon to view ${getText('agency').toLowerCase()} records.`,
      gridPageSize: 5,
      gridCurrentPage: 1,
      isSummaryModalVisible: false,
      qrisStartDate: null,
      qrisEndDate: null,
      summaryGridPageSize: 5,
      summaryGridCurrentPage: 1,
      summaryKeyAdd: '',
      currentOrganizationName: '',
      showAgencyReportButton: false,
      isArchivedReportsModalVisible: false
    };
  },
  watch: {
    filterOrganizationId: function (newVal) {
      if (newVal) {
        this.gridPrimaryEntity = { Id: newVal, EntityTypeId: 2 };
        if (locale === 'tn') {
          this.isBusy = true;
          const body = {
            orgId: newVal
          };
          this.$store
            .dispatch('getOrgByIdRequest', body)
            .then(async (response) => {
              this.isBusy = false;
              if (!response.disabled) {
                  this.showAgencyReportButton = true;
                } else {
                  this.showAgencyReportButton = false;
                }
            });
        } else {
          this.showAgencyReportButton = false;
        }
      } 
      else {
        this.gridPrimaryEntity = {};
        this.showAgencyReportButton = false;
      }
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    agencySelected(organization) {
      if (organization && organization.key) {
        this.gridKey = 'open' + organization.key;
        this.additionalColumns = this.agencyAdditionalColumns;
        this.organizationEntity = organization;
        this.filterOrganizationId = organization.key;
        this.currentOrganizationName = organization.value;
      } else {
        this.gridKey = 'closed';
        this.additionalColumns = [];
        this.organizationEntity = {};
        this.filterOrganizationId = '';
      }
    },
    countySelected(county) {
      if (county && county.key) {
        this.additionalColumns = [];
        this.countyEntity = county;
        this.filterCountyId = county.key;
      } else {
        this.gridKey = 'closed';
        this.additionalColumns = [];
        this.countyEntity = {};
        this.filterCountyId = '';
      }
    },
    nameSearched(name) {
      if (name) {
        this.gridKey = 'open';
        this.additionalColumns = [];
        this.searchedName = name;
        this.searchDefaultFilters[0].filterValue = name;
        this.gridDefaultFilters = this.searchDefaultFilters;
        this.filterOrganizationId = '';
        if (this.$refs.userGrid && this.$refs.userGrid.loadSummary) this.$refs.userGrid.loadSummary();
      } else {
        this.gridKey = 'closed';
        this.additionalColumns = [];
        this.searchedName = '';
        this.gridDefaultFilters = [];
        this.filterOrganizationId = '';
      }
    },
    shouldShowAdditionalActions(item) {
      return this.shouldShowAction(item);
    },
    shouldShowAction(item) {
      return true;
    },
    shouldShowReportLink(item) {
      return locale === 'tn' && !item.agencyIsClosed && Object.keys(this.gridPrimaryEntity).length > 0;
    },
    shouldShowArchivedReportsLink(item) {
      return item.hasArchivedComplianceReport;
    },
    closeTrainingReportModal() {
      this.isTrainingReportModalVisible = false;
      this.fileUrl = '';
    },
    closeArchivedReportsModal() {
      this.isArchivedReportsModalVisible = false;
      this.activeUser = {};
    },
    async getReport(item) {
      this.isTrainingReportModalVisible = true;
      const body = { username: item.userName, organizationId: this.filterOrganizationId };
      this.$store
        .dispatch('complianceReportRequest', body)
        .then((response) => {
          if (response && response.file) {
            this.fileUrl = response.file;
          }
        })
        .catch((errors) => {
          this.errors = errors;
        });  
    },
    async getArchivedReports(item) {
      this.activeUser = item;
      this.isArchivedReportsModalVisible = true;
    },
    async dirtyCheck() {
      return await new Promise((resolve, reject) => {
        if (this.isDirty && this.isAuthenticated) {
          const options = {
            title: 'Unsaved Data Warning',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm('Are you sure you want to close without saving?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.$emit('dirty', false);
                this.isDirty = false;
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch((error) => reject(error));
        } else resolve(true);
      });
    },
    rowAction(item) {
      if (item && item.id) {
        this.activeId = item.id;
        this.activeUser = item;
        this.showModal();
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    async closeModal() {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isModalVisible = false;
        this.activeId = 'closed';
        this.activeUser = {};
      }
    },
    formDirty(eventVal) {
      this.isDirty = eventVal;
      this.$emit('dirty', eventVal);
    },
    closeSummary() {
      this.isSummaryModalVisible = false;
    },
    getQrisStartDate() {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return this.qrisStartDate.toLocaleDateString('en-US', options);
    },
    getQrisEndDate() {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return this.qrisEndDate.toLocaleDateString('en-US', options);
    },
    setSummaryPageSize(size) {
      this.summaryGridPageSize = parseInt(size);
    },
    setSummaryCurrentPage(page) {
      this.summaryGridCurrentPage = parseInt(page);
    },
    async showSummary() {
      this.isBusy = true;
      this.$store
        .dispatch('qrisCycleRequest')
        .then(async (response) => {
          this.qrisStartDate = new Date(response.startDate);
          this.qrisEndDate = new Date(new Date(response.endDate).getTime() -86400);
          this.isBusy = false;
          this.isSummaryModalVisible = true;
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    async getAgencyReport() {
      this.isBusy = true;
      const body = { organizationId: this.filterOrganizationId };
      this.$store
        .dispatch('agencyReportRequest', body)
        .then((response) => {
          this.isBusy = false;
          if (response && response.report) {
            const orgName = this.strip(this.currentOrganizationName);
            let date = new Date().toLocaleString();
            date = date.replace(/\//g, '-');
            date = date.replace(/:/g, '-');
            date = date.replace(/,/g, '_');
            date = date.replace(/ /g, '');
            const fileName = `TNPAL_${orgName}_AgencySummaryReport_${date}.pdf`;
            this.savePdf(fileName, response.report);
            const options = { title: 'Download successful.' };
            this.$dialogs.alert(`Check your Downloads folder for a PDF of the report.`, options);
          }
        })
        .catch((errors) => {
          this.isBusy = false;
          this.errors = errors;
        });
    },
    strip(arr) {
      const arr2 = arr.split(' ');
      for (let item of arr2) {
        const index = arr2.indexOf(item);
        const ewc = item.endsWith(',');
        const ewp = item.endsWith('.');
        const e = item.length - 1;
        if (ewc || ewp) {
          item = item.slice(0, e);
          arr2[index] = item;
        }
        if (item.toLowerCase().includes('llc') || item.toLowerCase().includes('inc')) {
          arr2.splice(index, 1);
        }
      }
      return arr2.join(' ');
    },
    savePdf(strFileName, strData) {
      const pdfData = 'data:application/pdf;base64,' + escape(strData);

      const link = document.createElement('a');
      link.href = pdfData;
      link.download = strFileName;
      link.click();
    },
  }
};
</script>
